<template>
  <b-row v-if="loading">
    <b-col sm="9">
      <b-card
        no-body
        class="card-revenue-budget"
      >
        <b-card-header>
          <b-card-title>ข้อมูลการทำธุรกรรม</b-card-title>
          <b-card-text class="mr-25 mb-0">
            <!-- Updated 1 month ago -->
          </b-card-text>
        </b-card-header>
        <vue-apex-charts
          id="budget-chart"
          height="310"
          type="line"
          :options="_options"
          :series="_series"
        />
      </b-card>
    </b-col>
    <b-col sm="3">
      <b-row>
        <b-col sm="12">
          <DashboardThreeOp1 />
        </b-col>
        <b-col sm="12">
          <DashboardThreeOp2 />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BRow, BCol, BDropdown, BDropdownItem, BButton } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Ripple from 'vue-ripple-directive'
import { mapState } from 'vuex'
// import Dayjs from 'vue-dayjs'
// const dayjs = Dayjs
export default {
  components: {
    DashboardThreeOp1: () => import('./DashboardThreeOp1'),
    DashboardThreeOp2: () => import('./DashboardThreeOp2'),
    VueApexCharts,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading']),
    _series() {
      const { purchaseCredit, purchaseImage, timestamp } = this.results.transactionsData
      // console.clear()
      const _return = [
        {
          name: 'ยอดขาย',
          type: 'column',
          data: purchaseImage,
        },
        {
          name: 'ยอดเติม',
          type: 'column',
          data: purchaseCredit,
        },
      ]

      return _return
    },

    _options() {
      const self = this

      return {
        chart: {
          height: 350,
          type: 'line',
          animations: { enabled: false },
        },

        stroke: {
          width: [1, 4],
        },
        legend: {},
        dataLabels: {
          enabled: false, // แสดงตัวเลข
          enabledOnSeries: [0, 1],
          formatter: function (val, opts) {
            return self.withCommas(val, false)
          },
          offsetX: 0,
          offsetY: -10,
          // style: {
          //   fontSize: '14px',
          //   fontFamily: 'Helvetica, Arial, sans-serif',
          //   fontWeight: 'bold',
          //   // Kanit
          //   // fontcolor: '#F30808',
          //   // colors: ['#FFFCFC'],
          // },
          // background: {
          //   enabled: true,
          //   foreColor: '#fff',
          //   padding: 0,
          //   borderRadius: 2,
          //   borderWidth: 2,
          //   borderColor: '#fff',
          //   opacity: 100,
          //   dropShadow: {
          //     enabled: false,
          //     top: 1,
          //     left: 1,
          //     blur: 1,
          //     color: '#000',
          //     opacity: 0.45,
          //   },
          // },
        },
        labels: self.results?.transactionsData?.timestamp ?? [],
        xaxis: {
          labels: {
            formatter: function (value, timestamp) {
              return self.$date(value).locale('th').format('DD MMM') // The formatter function overrides format property
            },
          },
          // type: 'datetime',
        },
        // yaxis: [
        //   {
        //     title: {
        //       text: 'Website Blog',
        //     },
        //   },
        //   {
        //     opposite: true,
        //     title: {
        //       text: 'Social Media',
        //     },
        //   },
        // ],
      }
    },
  },
}
</script>
